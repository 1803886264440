/* Existing styles */
.cciengagesorted-dashboard-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  color: black;
  background-color: #8298b9;
}

.header-container {
  background-color: #8298b9;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.header-title {
  margin: 0;
  font-size: 1.5rem;
}

.header-buttons {
  display: flex;
  gap: 0.5rem;
}

.cciengagesorted-main-content {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
}

.sidebar {
  width: 250px;
  background-color: #8298b9;
  transition: transform 0.3s ease;
}

.sidebar-hidden {
  transform: translateX(-100%);
}

.cciengagesorted-app-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px;
}

.cciengagesorted-scrollable-table {
  overflow-y: auto;
  max-height: calc(100vh - 200px);
}

.cciengagesorted-centered-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
  text-align: left;
  font-size: 1rem;
}

.cciengagesorted-centered-table th,
.cciengagesorted-centered-table td {
  border: 1px solid #ddd;
  padding: 8px;
  color: black;
}

.cciengagesorted-centered-table th {
  background-color: gray;
  text-align: center;
  font-weight: bold;
  color: black;
}

.cciengagesorted-centered-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.cciengagesorted-centered-table tbody tr:nth-child(odd) {
  background-color: lightgray;
}

.cciengagesorted-centered-table tbody tr:hover {
  background-color: #f1f1f1;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  right: 5rem;
}

.toggle-filter-sidebar-btn {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1000;
  background: none;
  border: none;
  cursor: pointer;
}

.hamburger-menu-img {
  width: 30px;
  height: auto;
}

.download-button {
  height: 50px;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.9rem;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
}

.download-button:hover {
  background-color: #45a049;
}

/* New styles for the search bar */
.search-bar {
  width: 60%; /* Increase width as needed */
  max-width: 600px; /* Optional max width */
  padding: 10px; /* Adjust padding */
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  margin-right: 10px; /* Adjust spacing between the search bar and other elements */
}
