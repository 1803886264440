/* CCIDemoResponseDashboard.css */
.ccidemographs-dashboard-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  color: black; /* Ensure text color is black */
}

.ccidemographs-main-content {
  display: flex;
  flex-grow: 1;
  padding-top: 0;
  background-color: #8298b9;
}

.sidebar {
  display: block; /* Sidebar visible */
}

.sidebar-hidden {
  display: none; /* Sidebar hidden */
}


.ccidemographs-grid-container {
  display: flex;
  flex-direction: column; /* Stack the charts vertically */
  flex-grow: 1;
  justify-content: center;
  align-items: stretch; /* Ensure the charts fill the width */
  padding: 1rem;
  width: 100%;
}

.ccidemographs-dropdown {
  margin-bottom: 1rem; /* Space between the dropdown and the cards */
}

.ccidemographs-card {
  flex: 1;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  max-width: 100%;
  height: 400px; /* Default height */
  margin-bottom: 1rem; /* Space between the cards */
  overflow: hidden; /* Ensure no overflow */
  cursor: pointer; /* Indicate that the card is clickable */
  transition: height 0.3s ease; /* Smooth transition for height change */
}

.ccidemographs-card.expanded {
  height: 500px; /* Expanded height */
}

.ccidemographs-card-header {
  font-size: 1.2em;
  margin-bottom: 8px;
  font-weight: bold;
  padding: 8px;
  border-bottom: 1px solid #ddd;
  background-color: grey;
}

.ccidemographs-card-content {
  height: 100%; /* Ensure the chart container fills the parent container */
  width: 100%; /* Ensure the chart container fills the parent container */
  overflow: hidden; /* Ensure no overflow */
  position: relative; /* Needed for scrollable content */
  max-height: 600px;
}

.scrollable-content {
  height: 100%; /* Fill the height of the parent */
  overflow-y: auto; /* Enable vertical scrolling */
  padding-right: 1rem; /* Add some padding to the right */
}

.CCIDemoResponse-internal-charts {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.CCIDemoResponse-internal-chart-container {
  flex: 1;
  min-width: 300px; /* Ensure a minimum width for each chart container */
  margin: 1rem;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.apexcharts-canvas {
  height: 100% !important; /* Make the chart fill the height */
  width: 100% !important;  /* Make the chart fill the width */
}
