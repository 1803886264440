/* CCITextThemeDashboard.css */

.ccitexttheme-dashboard-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  color: black; /* Ensure text color is black */
  background-color: #8298b9;
}

.ccitexttheme-main-content {
  display: flex;
  flex-grow: 1;
  padding-top: 0; /* Removed the 20px padding */
}

.ccitexttheme-chart-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column; /* Align items vertically */
  align-items: center;
  padding: 1rem;
  width: calc(100% - 800px); /* Adjusted width to fill the space next to the sidebar */
}

#main.ccitexttheme-chart {
  width: 100%;
  height: 100%; /* Adjust the height as needed */
  background-color: white;
  border-radius: 10px;
  padding: 2rem;
}

.question-selector {
  margin-bottom: 1rem;
  text-align: center;
}

.question-selector label {
  margin-right: 0.5rem;
}

.question-selector select {
  padding: 0.5rem;
  font-size: 1rem;
}
