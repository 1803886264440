/* CCISurveySpecificDashboard.css */

.ccisurveyspecific-dashboard-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  color: black; /* Ensure text color is black */
  background-color: #8298b9;
}

.ccisurveyspecific-main-content {
  display: flex;
  flex-grow: 1;
  position: relative; /* Ensure child elements are positioned correctly */
  overflow: hidden; /* Prevent overflowing out of the viewport */
}

.ccisurveyspecific-content-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden; /* Ensure content does not overflow */
}

.sidebar {
  display: block; /* Sidebar visible */
}

.sidebar-hidden {
  display: none; /* Sidebar hidden */
}

.ccisurveyspecific-charts-container {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem;
  width: calc(100%); /* Adjust width to account for sidebar */
  height: 100%;
  overflow-x: auto; /* Enable horizontal scrolling if needed */
}

.ccisurveyspecific-chart {
  flex: 1 1 calc(33.33% - 1rem); /* 3 charts per row with spacing */
  padding: 0px 10px;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin: 0.5rem;
  max-width: calc(
    33.33% - 1rem
  ); /* Ensure max width is the same as flex basis */
  height: 600px; /* Auto height to fit content */
}

.ccisurveyspecific-chart-title {
  background-color: gray;
  padding: 0.5rem;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
}

/* Header styling within each card */
.ccidemographics-card-header {
  font-size: 1.2em;
  text-align: center;
  /* margin-bottom: 4px; */
  font-weight: bold;
  padding: 4px;
  /* border-bottom: 1px solid #ddd; */
  background-color: grey;
  border-radius: 5px;
}

.ccisurveyspecific-chart > h5 {
  font-size: 1rem;
  font-weight: normal;
  margin: 0px;
  padding: 0px 5px;
}

.ccisurveyspecific-chart-container {
  height: 100%; /* Ensure the chart container fills the parent container */
  width: 100%; /* Ensure the chart container fills the parent container */
}

.apexcharts-canvas {
  height: 100% !important; /* Make the chart fill the height */
  width: 100% !important; /* Make the chart fill the width */
}
