/* D03Scatterplot01.css */

.scatterplot-chart-container1 {
  height: 100%; /* Let it fill the entire height */
  width: 50%;  /* Fill the entire width */
  justify-content: center;
}

.apexcharts-canvas {
  height: 100% !important;
  width: 100% !important;
}

.apexcharts-inner {
  height: 100% !important;
  width: 100% !important;
}

.scatterplot-legend-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
  width: 100%; /* Ensure the legend uses full width */
}

.scatterplot-legend-item {
  display: flex;
  align-items: center;
  margin-right: 15px;
  font-size: 14px;
}

.scatterplot-legend-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}
