.ccitextmap-dashboard-container {
  display: flex;
  flex-direction: column;
  height: 170vh;
  background-color: #8298b9;
}

.ccitextmap-main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.ccitextmap-filters {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.ccitextmap-dropdown {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.ccitextmap-dropdown label {
  margin-bottom: 5px;
  font-weight: bold;
}

.ccitextmap-dropdown select {
  padding: 8px;
  font-size: 1rem;
}

.ccitextmap-charts-container {
  flex: 1;
  background-color: white;
}

.ccitextmap-chart {
  display: flex;
  flex-direction: column;
  height: 130vh;
}

.ccitextmap-chart-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.ccitextmap-chart-container {
  height: 100%;
  width: 100%;
}
