/* Dashboard.css */
.CCITotalengage-container {
  display: flex;
  flex-direction: column;
  height: 120vh;
  overflow: hidden;
  background-color: #8298b9;
}

.CCITotalengage-main-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
}

.CCITotalengage-sidebar {
  display: block; /* Sidebar visible */
}

.CCITotalengage-sidebar-hidden {
  display: none; /* Sidebar hidden */
}

.CCITotalengage-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1rem;
}

.CCITotalengage-content-row {
  display: flex;
  flex-direction: row;
  gap: 16px; /* Space between the pie chart and text box component */
  padding: 12px;
}

.CCITotalengage-card {
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  background-color: #fff; /* Ensure card background is white */
}

.CCITotalengage-card-header {
  font-size: 1.2em;
  margin-bottom: 8px;
  font-weight: bold;
  padding: 8px;
  border-bottom: 1px solid #ddd;
  background-color: grey;
}

.CCITotalengage-card-content {
  padding: 1rem;
}

.CCITotalengage-pie-chart-container,
.CCITotalengage-text-box-container {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.CCITotalengage-pie-chart {
  max-width: 600px;
  margin: 0 auto; /* Center the pie chart */
}

.CCITotalengage-chart-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

/* Styles for the donut charts */
.CCITotalengage-donut-chart {
  flex: 1 1 20%; /* Grow to fill the container and be responsive */
  max-width: 600px; /* Increase the max-width to make them larger */
  min-width: 200px; /* Ensure they don't get too small */
}

.CCITotalengage-hamburger-menu-img {
  width: 24px;
  height: 24px;
}

/* Additional styles for TextBoxComponent */
.CCITotalengage-text-box-container {
  display: flex;
  flex-direction: column; /* Flex-direction can be row if boxes should be side by side */
  gap: 16px;
  padding: 16px;
}

.CCITotalengage-text-box {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
