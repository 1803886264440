/* Container for the entire dashboard */
.dashboard-container {
  display: flex;
  height: 150vh;
  width: 100vw; /* Ensure container uses the full viewport width */
  overflow: hidden; /* Prevent horizontal scroll */
  flex-direction: column; /* Ensure header is at the top */
  background-color: #8298b9;
}

/* Main content area that flexes and handles overflow */
.main-content {
  display: flex;
  flex: 1;
  overflow: hidden; /* Prevent horizontal scroll */
  position: relative;
}

/* Header styling */
.header {
  z-index: 1005; /* Ensure the header is above the sidebar */
  position: relative;
}


/* Wrapper for the main content and sidebars */
.cciscore-detail-content-wrapper {
  display: flex;
  flex-grow: 1;
  width: 100%;
  overflow: hidden; /* Prevent horizontal scroll */
}

/* Grid container for the dashboard cards */
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Exactly 3 columns */
  gap: 16px;
  padding: 16px;
  box-sizing: border-box; /* Include padding in width calculations */
  overflow-x: hidden; /* Prevent horizontal scroll */
  width: 100%; /* Ensure grid container uses full available width */
  max-width: 1o0vw; /* Prevent exceeding viewport width */
  flex: 1;
}

/* Card styling for each visualization */
.card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: auto; /* Allow card height to adjust based on content */
  box-sizing: border-box; /* Include padding in width calculations */
  max-width: 100%; /* Ensure card does not exceed the container width */
  overflow: hidden; /* Prevent content from overflowing */
}

/* Header styling within each card */
.card-header {
  font-size: 1.2em;
  margin-bottom: 8px;
  font-weight: bold; /* Added for better readability */
  overflow: hidden; /* Prevent content from overflowing */
  white-space: nowrap; /* Ensure header text doesn’t wrap */
  text-overflow: ellipsis; /* Display ellipsis if text is too long */
  background-color: grey;
}

/* Charts Wrapper styling */
.cciscore-detail-charts-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  padding-top: 1rem; /* Ensure charts start below the header */
  width: 100%;
  height: 130vh;
}

/* Charts Container styling */
.cciscore-detail-charts-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  width: 100%;
  height: 130vh;
}

/* Chart Row styling */
.cciscore-detail-chart-row {
  display: grid;
  grid-template-columns: 1fr 4fr; /* 1/5 for score and 4/5 for heatmap */
  gap: 1rem;
  width: 100%;
  height: 800px;
}

/* Individual Score styling */
.score {
  background-color: #f9f9f9;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 200px; /* Minimum width to prevent collapsing */
}

/* Individual Heatmap styling */
.heatmap {
  background-color: #fff;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  align-items: center;
  justify-content: center;
}

/* Demographic Selector styling */
.demographic-selector {
  padding: 1rem;
}

/* Filter Sidebar Toggle Button styling */
.toggle-filter-sidebar-btn {
  position: absolute;
  top: 1.6rem;
  right: 4rem;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1006; /* Ensure the button is above the sidebar and header */
}

.hamburger-menu-img {
  width: 30px;
  height: 30px;
}
