.cciresponse-range-dashboard-container {
  display: flex;
  flex-direction: column;
  height: 100vh;

  color: black;
  background-color: #8298b9;
}

.cciresponse-range-main-content {
  display: flex;
  flex-grow: 1;
  /* margin-left: 10%; */
  background-color: #8298b9;
}

.cciresponse-range-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
  padding-left: 130px; /* Adjusted for the legend width */
}

.cciresponse-range-charts-wrapper {
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  flex-direction: row; /* Ensure charts are in a row */
}

.custom-legend-container {
  position: absolute;
  top: 1.4%;
  left: 10px; /* Move to the left side */
  width: 260px; /* Adjusted width */
  background-color: #f9f9f9;
  border-right: 1px solid #ddd; /* Changed border to right */
  height: 97%;
  padding: 0rem 1rem; /* Reduced padding */
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  border-radius: 10px;
  margin-left: 10%; /*  offset from left side of page   */
}

.custom-legend {
  display: flex;
  flex-direction: column;
  gap: 5px; /* Reduced space between legend items */
}

.legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px; /* Reduced margin */
  font-size: 14px;
}

.legend-marker {
  width: 12px;
  height: 12px;
  margin-right: 5px;
  display: inline-block;
}

.cciresponse-range-charts-container {
  display: flex;
  flex-direction: column; /* Stacks charts vertically */
  gap: 1rem; /* Space between charts */
  padding: 1rem;
  width: calc(75%); /* Adjusted  overall chart width */
  height: 100%; /* Ensures the container takes full height */
  margin-left: 12%; /* offset for legend */
}

.cciresponse-range-chart {
  flex: 1; /*Allows the chart to expand and fill available space  */
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  box-sizing: border-box; /* Ensures padding and border are included in the width */
}

.cciresponse-range-chart-title {
  font-size: 1.2em;
  margin-bottom: 8px;
  font-weight: bold;
  padding: 8px;
  border-bottom: 1px solid #ddd;
  background-color: grey;
}

.cciresponse-range-chart-container {
  height: 80%;
  width: 100%;
  position: relative;
}

.demographic-selector {
  position: relative;
  z-index: 1000;
}