.generate-report-dashboard-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  color: black; /* Ensure text color is black */
  background-color: #8298b9;
}

.generate-report-main-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1rem;
  overflow: auto;
  margin-left: 10px; /* Adjust to ensure main content is beside the sidebar */
  background-color: #8298b9;
}

.generate-report-sidebar-open {
  display: block; /* Ensure the sidebar is visible */
  position: absolute;
  top: 4rem; /* Adjust this value to place it under the header */
  left: 0;
  height: calc(100% - 4rem); /* Adjust to ensure it doesn't overlap with the header */
  background-color: #f8f9fa;
  overflow-y: auto;
  transition: width 0.2s ease; /* Simplify transition for sidebar toggle */
  width: 250px; /* Adjust width as needed */
}

.generate-report-sidebar-closed {
  display: none; /* Ensure the sidebar is hidden */
}

.charts-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
  height: 100%;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  background-color: #8298b9;
  flex-direction: row;
  padding-top: 2rem;
}

.chart {
  flex: 1 1 calc(75% - 1rem);
  max-width: calc(75% - 1rem);
  padding: 1rem;
  background-color: #fff;
  min-height: 550px;
  justify-self: center; /* Centers the individual charts in the grid */
  align-self: center; /* Ensures individual charts are vertically centered */
  display: flex;
  align-items: center; /* Centers content within the chart */
  justify-content: center; /* Centers content within the chart */
}


button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.generate-report-controls {
  display: flex;
  justify-content: flex-end; /* Aligns the button to the right */
  margin-bottom: 20px; /* Space between the button and other content */
  padding-right: 1rem; /* Optional padding on the right side */
}
