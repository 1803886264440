/* Styles for the header */
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: #667894;
  color: white;
}

.header-title {
  font-size: 1.5em;
  margin: 0;
}

.header-buttons {
  display: flex;
  align-items: center;
  z-index: 1002;
}

.toggle-filter-sidebar-btn, .refresh-button, .toggle-sidebar-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.filter-icon-img, .sidebar-img, .refresh-img {
  width: 24px; /* Default size, adjustable */
  height: 24px; /* Default size, adjustable */
}

.header-buttons img {
  width: 24px; /* Default size for images in header buttons */
  height: 24px; /* Default size for images in header buttons */
}

.header-buttons .filter-button-container, .header-buttons .refresh-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px; /* Space between buttons, adjustable */
}

/* Additional styles for positioning */
.filter-button-container {
  /* Custom styles for positioning the filter button */
  margin-right: 20px; /* Adjust spacing as needed */
}

.refresh-button-container {
  /* Custom styles for positioning the refresh button */
  margin-right: 20px; /* Adjust spacing as needed */
}
