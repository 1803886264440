/* CCIDistributionDashboard.css */

.ccidistribution-dashboard-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden; /* Prevent overflowing out of the viewport */
  background-color: #8298b9;
}

.ccidistribution-main-content {
  display: flex;
  flex-grow: 1;
  position: relative; /* Ensure child elements are positioned correctly */
  overflow: hidden; /* Prevent overflowing out of the viewport */
}

.sidebar {
  display: block; /* Sidebar visible */
}

.sidebar-hidden {
  display: none; /* Sidebar hidden */
}

.ccidistribution-charts-container {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  padding: 1rem;
  width: calc(100% - 300px); /* Adjust width to account for sidebar */
  height: 100%;
  overflow-x: auto; /* Enable horizontal scrolling if needed */
  justify-content: flex-start;
  align-items: flex-start;
}

.ccidistribution-row {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
}

.ccidistribution-chart-card {
  /* flex: 0 0 calc(33.33% - 0.5rem); 3 charts per row with spacing */
  /* padding: 1rem; */
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin: 0.4rem;
  height: 400px; /* Fixed height to fit content */
}

.ccidistribution-chart-card:nth-child(1) {
  flex: 0 0 20%; /* First chart card takes 20% of the available width */
}

.ccidistribution-chart-card:nth-child(2) {
  flex: 0 0 47%; /* Second chart card takes 47% of the available width */
}

.ccidistribution-chart-card:nth-child(3) {
  flex: 0 0 32%; /* Third chart card takes 32% of the available width */
}

.ccidistribution-chart-title {
  background-color: grey;
  padding: 0.5rem;
  text-align: center;
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0.5rem;
  border-radius: 0.25rem;
}

.ccidistribution-chart-container {
  height: 100%; /* Ensure the chart container fills the parent container */
  width: 100%; /* Ensure the chart container fills the parent container */
}

.apexcharts-canvas {
  height: 100% !important; /* Make the chart fill the height */
  width: 100% !important; /* Make the chart fill the width */
}

.ccidistribution-table {
  /* font-family: "RobotoMono"; */
  display: flex;
  flex-direction: column;
  width: 97.5%;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 1rem;
  margin: 0.5rem; /* Add margin to match chart card styling */
}

.table-header,
.table-row {
  display: flex;
  padding: 0.4rem 0;
}

.table-header {
  font-weight: bold;
  border-bottom: 2px solid #ddd;
  margin-bottom: 0.5rem;
  color: black; /* Ensure text color is black */
}

.table-column {
  flex: 1;
  text-align: center;
  color: black; /* Ensure text color is black */
}
