.cciyoyarea-dashboard-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    color: black; /* Ensure text color is black */
    width: 100%;
    background-color: #8298b9;
}

.cciyoyarea-main-content {
    display: flex;
    flex-grow: 1;
}

.sidebar {
    display: block; /* Sidebar visible */
}

.sidebar-hidden {
    display: none; /* Sidebar hidden */
}

.cciyoyarea-charts-container {
    display: flex;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    padding: 1rem;
    width: 100%;
}

.cciyoyarea-chart {
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    height: 100%; /* Fill the container height */
    width: 100%;
}

.cciyoyarea-chart-title {
    background-color: #f2f2f2;
    padding: 0.5rem;
    text-align: center;
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    border-radius: 0.25rem;
}

.cciyoyarea-chart-container {
    height: 100%; /* Ensure the chart container fills the parent container */
    width: 100%; /* Ensure the chart container fills the parent container */
}

.apexcharts-canvas {
    height: 100% !important; /* Make the chart fill the height */
    width: 100% !important;  /* Make the chart fill the width */
}
