/* CCISelfEngageDashboard.css */

.cciselfengage-dashboard-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  color: black; /* Ensure text color is black */
  background-color: #8298b9;
}

.cciselfengage-main-content {
  display: flex;
  /* flex-grow: 1; */
  position: relative;
}

.cciselfengage-navigation-sidebar-open {
  display: block; /* Ensure the sidebar is visible */
  position: absolute;
  top: 4rem; /* Adjust this value to place it under the header */
  left: 0;
  height: calc(
    100% - 4rem
  ); /* Adjust to ensure it doesn't overlap with the header */
  background-color: #f8f9fa;
  overflow-y: auto;
  transition: width 0.2s ease; /* Simplify transition for sidebar toggle */
  width: 250px; /* Adjust width as needed */
}

.cciselfengage-navigation-sidebar-closed {
  display: none; /* Ensure the sidebar is hidden */
}

.cciselfengage-charts-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.cciselfengage-charts-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem;
  width: 99%;
}

.cciselfengage-chart {
  flex: 1 1 calc(33.333% - 1rem); /* Allow up to three charts per line */
  max-width: calc(33.333% - 1rem);
  padding: 0 0.5rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  height: 40vh;
}

.cciselfengage-chart-title {
  font-size: 1.2em;
  margin-bottom: 8px;
  font-weight: bold;
  padding: 8px;
  border-bottom: 1px solid #ddd;
  background-color: grey;
  border-radius: 8px;
}

.cciselfengage-chart-container {
  height: 100%;
  width: 100%;
}

.toggle-filter-sidebar-btn {
  position: fixed;
  top: 1.5rem; /* Adjust to be below the header */
  right: 5rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.hamburger-menu-img {
  width: 30px;
  height: 30px;
}

.Q-bold {
  font-weight: 600;
}

.apexcharts-tooltip-text {
  padding: 0px 8px;
}

.apexcharts-tooltip-text ul {
  list-style-type: none;
}

#tooltip-title {
  background-color: #d3d3d3;
  font-weight: 500;
  padding: 2px 5px;
}
