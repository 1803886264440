/* EngageSurveyDashboard.css */

.engagesurvey-dashboard-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden; /* Prevent overflowing out of the viewport */
  background-color: #8298b9;
}

.engagesurvey-main-content {
  display: flex;
  flex-grow: 1;
  position: relative; /* Ensure child elements are positioned correctly */
  overflow: hidden; /* Prevent overflowing out of the viewport */
  width: 100%; /* Ensure it uses the full width */
}

.sidebar {
  display: block; /* Sidebar visible */
}

.sidebar-hidden {
  display: none; /* Sidebar hidden */
}

.engagesurvey-charts-container {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  padding: 0 0.5rem 0.5rem 0.5rem;
  width: 100%; /* Ensure it uses the full width */
  height: calc(100vh - 80px); /* Adjust height to account for the header */
  overflow-y: auto; /* Enable vertical scrolling if needed */
  justify-content: flex-start;
  align-items: flex-start;
}

.engagesurvey-chart-card {
  flex: 1 1 calc(50% - 0.5rem); /* 2 charts per row with reduced spacing */
  padding: 0.35rem; /* Reduce padding */
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin: 0.25rem; /* Reduce margin */
  max-width: calc(
    50% - 0.7rem
  ); /* Ensure max width is the same as flex basis */
  height: 60vh; /* Adjust height to fit content */
  overflow: auto; /* Prevent content from overflowing */
}

.engagesurvey-chart-title {
  background-color: grey;
  padding: 0.35rem;
  margin: 0;
  text-align: center;
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.25rem;
  border-radius: 0.25rem;
}

.engagesurvey-chart-container {
  height: 100%; /* Ensure the chart container fills the parent container */
  width: 100%; /* Ensure the chart container fills the parent container */
  padding: 0;
}

.apexcharts-canvas {
  height: 100% !important; /* Make the chart fill the height */
  width: 100% !important; /* Make the chart fill the width */
}

.header-container {
  background-color: #8298b9; /* Adjust the background color as needed */
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white; /* Ensure text color is white */
  z-index: 1001; /* Ensure header is on top */
}

.header-title {
  margin: 0;
  font-size: 1.5rem;
}

.header-buttons {
  display: flex;
  gap: 0.5rem;
}

.hamburger-menu-img {
  width: 30px;
  height: auto;
}

p {
  padding: 0 0 0 30px;
  margin: 0;
}
