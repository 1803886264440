/* Container for the entire dashboard */
.cciimpactarea-dashboard-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  color: black;
  background-color: #8298b9;
}

/* Main content area with relative positioning */
.cciimpactarea-main-content {
  display: flex;
  flex-grow: 1;
  position: relative;
  background-color: #8298b9;
}

/* Wrapper for the main content, ensuring full width */
.cciimpactarea-content-wrapper {
  display: flex;
  flex-grow: 1;
  width: 100%;
  /* height: 80%; */
}

/* Wrapper for charts, allowing flex column layout */
.cciimpactarea-charts-wrapper {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;

  overflow: hidden;
  /* padding-top: 0.5rem; */
}

/* Container for charts, centered with padding */
.cciimpactarea-charts-container {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  width: 100%;
}

/* Individual chart styling with padding, border, and shadow */
.cciimpactarea-chart {
  flex: 1;
  padding: 1rem;
  /* border: 1px solid #ddd; */
  border-radius: 0.5rem;
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); */
  background-color: #fff;
  max-width: 75%;
  /* max-height: 95%; */
}

/* Chart title styling */
.cciimpactarea-chart-title {
  background-color: gray;
  padding: 0.5rem;
  text-align: center;
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
}

/* Ensure the chart container fills its parent */
/* .cciimpactarea-chart-container {
  height: 100%;
  width: 100%;
} */

/* ApexCharts canvas adjustments */
/* .apexcharts-canvas {
  height: 100% !important;
  width: 100% !important;
} */

/* Button for toggling the filter sidebar */
.toggle-filter-sidebar-btn {
  position: absolute;
  top: 1rem;
  right: 4rem;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1001;
}

/* Image for the hamburger menu icon */
.hamburger-menu-img {
  width: 30px;
  height: 30px;
}

/* Navigation sidebar styles */
.navigation-sidebar-open {
  transform: translateX(0);
  visibility: visible;
  opacity: 1;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.navigation-sidebar-closed {
  transform: translateX(-100%);
  visibility: hidden;
  opacity: 0;
  transition: transform 0.3s ease, opacity 0.3s ease;
}
.legend-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 0px;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.legend-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

/* Add additional styles as needed for the FilterSidebar and other elements */
