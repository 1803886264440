/* CCITextResponseTable.css */

.ccitextresponse-dashboard-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    color: black; /* Ensure text color is black */
    background-color: #8298b9;
  }
  
  .ccitextresponse-main-content {
    display: flex;
    flex-grow: 1;
  }
  
  .sidebar {
    display: block; /* Sidebar visible */
  }
  
  .sidebar-hidden {
    display: none; /* Sidebar hidden */
  }
  
  .ccitextresponse-app-container {
    /* padding: 8px; */
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #8298b9;
  }
  
  .ccitextresponse-controls {
    display: flex;
    margin-bottom: 20px; /* Add space between the controls and the table */
    border-radius: 10px;
    padding: 5px;
    align-items: baseline;
    gap: 25px;
  }
  .searchbar {
  height: 50px;
  border-radius: 5px;
  align-items: baseline;
  }
  .searchbar2 {
    height: 50px;
    border-radius: 5px;
    padding-top: 0px;
    }
  
  .ccitextresponse-scrollable-table {
    overflow-y: auto;
    max-height: calc(100vh - 200px); /* Adjust height to fit within the screen */
    background-color: lightgray;
  }
  
  .ccitextresponse-centered-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: auto;
    text-align: left;
    font-size: 1rem;
  }
  
  .ccitextresponse-centered-table th,
  .ccitextresponse-centered-table td {
    border: 1px solid #ddd;
    padding: 8px;
    color: black; /* Ensure text color is black */
  }
  
  .ccitextresponse-centered-table th {
    background-color: gray;
    text-align: center;
    font-weight: bold;
    color: white; /* Ensure text color is black */
  }
  
  .ccitextresponse-centered-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .ccitextresponse-centered-table tbody tr:hover {
    background-color: #f1f1f1;
  }
  .download-button {
    height: 50px;
    padding: 0px 10px;
    border-radius: 5px;
    font-size: 0.9rem;
    background-color: #4caf50;
    color: white;
    border: none;
    cursor: pointer;
    margin-bottom: 20px;
  }
  
  .download-button:hover {
    background-color: #45a049;
  }