.custom-login-wrapper {
  position: relative;
  font-family: "Ubuntu", sans-serif;
  color: #ffffff;
}

.custom-shape-divider-bottom-1710522688 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1710522688 svg {
  position: relative;
  display: block;
  width: calc(215% + 1.3px);
  height: 70vh;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1710522688 .shape-fill {
  fill: #004983;
}

#login-img-container {
  position: relative;
}

#login-image {
  z-index: -1;
  height: 90vh;
  position: absolute;
  left: 18vw;
  top: 5vh;
  -webkit-mask-image: linear-gradient(transparent, black 30%);
  mask-image: linear-gradient(transparent, black 27.5%);
  overflow: hidden;
}

#login-logo {
  z-index: 10;
  height: 13vh;
  position: absolute;
  left: 50vw;
  bottom: 0vh;
  overflow: hidden;
}

#login-page-title {
  position: relative;
}

#login-page-title > h1 {
  position: absolute;
  font-size: 4rem;
  color: #253546;
  margin-left: 10vw;
  z-index: 10;
  text-shadow: 0px 0px 10px white;
}

#login-form {
  height: 10vh;
  width: 30vw;
  z-index: 1;
  position: relative;
  left: 62.5vw;
  top: 50vh;
  border: 3px solid #0083ca;
  border-radius: 10px;
  padding: 20px;
  background-color: #ffffff;
}

.psw {
  color: #494949;
}
