/* NavigationSidebar.css */

.navigation-sidebar {
  position: fixed;
  top: 5rem; /* Adjust based on your header height */
  left: 0;
  height: calc(100% - 5rem); /* Adjust based on your header height */
  width: 250px; /* Default width */
  background-color: #5b667d;
  overflow-y: auto;
  transition: transform 0.3s ease, width 0.3s ease, visibility 0.3s ease, opacity 0.3s ease; /* Ensure all transitions are covered */
  color: white;
  display: flex;
  flex-direction: column;
  z-index: 1006;
  border-radius: 25px;
  transform: translateX(-250px); /* Start off-screen when closed */
  visibility: hidden;
  opacity: 0;
}

.navigation-sidebar-open {
  transform: translateX(0); /* Slide in */
  visibility: visible;
  opacity: 1;
}

.navigation-sidebar-closed {
  transform: translateX(-250px); /* Slide out */
  visibility: hidden;
  opacity: 0;
}

.navigation-sidebar-header {
  text-align: center;
  padding: 1rem;
  background-color: #5b667d;
}

.navigation-sidebar-logo {
  max-width: 80%;
  height: auto;
  border-radius: 10px;
}

.navigation-sidebar-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  flex-grow: 1;
  background-color: #5b667d;
}

.navigation-sidebar-menu li {
  padding: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.navigation-sidebar-menu li:hover {
  background-color: #444;
}

.navigation-sidebar-menu li:last-child {
  margin-bottom: 0rem;
}
