/* App.css */
body {
  margin: 0;
  font-family: 'Ubuntu', sans-serif;
  color: black;
  overflow-x: hidden;
}

section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 400px;
  padding: 100px 20vw;
}

.custom-shape-divider-bottom-1710522688 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1710522688 svg {
  position: relative;
  display: block;
  width: calc(215% + 1.3px);
  height: 70vh;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1710522688 .shape-fill {
  fill: #004983;
  bottom: 0;
}

#login-img-container {
  position: relative;
}

#login-image {
  z-index: -1;
  height: 90vh;
  position: absolute;
  left: 18vw;
  top: 0vh;
  bottom: 0vh;
  -webkit-mask-image: linear-gradient(transparent, black 30%);
  mask-image: linear-gradient(transparent, black 27.5%);
  overflow: hidden;
}

#login-page-title {
  position: relative;
}

#login-page-title > h1 {
  position: absolute;
  font-size: 5.5rem;
  color: #253546;
  margin-left: 10vw;
  z-index: 10;
  text-shadow: 0px 0px 10px white;
}

#auth-container {
  position: relative;
  left: 62.5vw;
  top: 50vh;
  width: 30vw;
}

/* Add styles for header buttons to ensure visibility and functionality */
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: #343a40;
  color: white;
}

.header-title {
  font-size: 1.5em;
  margin: 0;
}

.header-buttons {
  display: flex;
  align-items: center;
}

/* Styles for the buttons */
.toggle-sidebar-btn,
.toggle-filter-sidebar-btn,


.toggle-sidebar-btn:hover,
.toggle-filter-sidebar-btn:hover,
.refresh-button:hover,

.sidebar-img,
.hamburger-menu-img,
.refresh-button img {
  width: 24px;
  height: 24px;
}
