/* FilterSidebar.css */

.filter-sidebar {
  position: fixed;
  top: 5rem; /* Aligns with header height */
  right: 0;
  height: calc(100% - 5rem); /* Full height minus header */
  width: 250px;
  background-color: #5b667d;
  overflow-y: auto;
  transition: width 0.2s ease, right 0.2s ease;
  color: black;
  display: flex;
  flex-direction: column;
  z-index: 1006;
  border-radius: 25px 0 0 25px; /* Rounded corners on the left */
  
}

.filter-sidebar-content {
  padding: 1rem;
  flex-grow: 1;
  background-color: #5b667d;
}

.clear-all-button {
  margin: 1rem;
  padding: 0.5rem 1rem;
  background-color: #ff0000;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  align-self: flex-start;
}

.clear-all-button:hover {
  background-color: #cc0000;
}

.filter-sidebar-open {
  width: 250px;
}

.filter-sidebar-closed {
  width: 0;
}
