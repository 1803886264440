/* Container for the entire dashboard */
.ccidemographics-dashboard-container {
  display: flex;
  flex-direction: column;
  height: 215vh; /* Adjusted for full viewport height */
  width: 100vw;
  overflow: hidden; /* Prevent horizontal scroll */
  background-color: #8298b9;
  padding-right: 20px;
}

/* Main content area that flexes and handles overflow */
.ccidemographics-main-content {
  display: flex;
  flex: 1;
  overflow: hidden;
  position: relative;
  margin-top: 10px;
}

/* Sidebar styling */
.ccidemographics-sidebar-open,
.ccidemographics-sidebar-closed {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 250px;
  background-color: #f8f9fa;
  overflow-y: auto;
  transition: transform 0.3s ease;
  z-index: 1000;
}

.ccidemographics-sidebar-open {
  transform: translateX(0);
}

.ccidemographics-sidebar-closed {
  transform: translateX(-100%);
}

/* Filter sidebar styling */

/* Grid container for the dashboard cards */
.ccidemographics-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  padding: 12px;
  box-sizing: border-box;
  overflow-x: hidden;
  width: 100%;
  max-width: 100vw;
  flex: 1;
}

/* Card styling for each visualization */
.ccidemographics-card {
  background: #fff;
  border-radius: 8px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  padding: 0px 10px;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow: hidden;
}

/* Header styling within each card */
.ccidemographics-card-header {
  font-size: 1.2em;
  margin-bottom: 8px;
  font-weight: bold;
  padding: 8px;
  /* border-bottom: 1px solid #ddd; */
  background-color: grey;
}

/* Chart container to manage overflow */
.bar-chart-container {
  /* max-height: 1000px; or another appropriate height */
  overflow-y: auto;
}
.ccidemographics-right-sidebar {
  margin-top: 0.8rem;
}

.ccidemographics-right-sidebar .ccidemographics-card-header {
  padding: 0 5px;
}

.ccidemographics-card-content {
  padding: 0 0 1.5rem 0;
}

.respondents-summary h3,
p {
  font-size: 1.2rem;
  font-weight: 500;
  padding: 0;
  margin: 5px;
}
