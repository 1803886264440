.engagement-summary-chart-container {
    width: 80%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .engagement-summary-chart-container > div {
    width: 100%;
    height: 100%;
  }
  